import Home from '../pages/Home'
import Search from "../pages/Search"
import User from "../pages/user/User";
import Cashback from "../pages/user/Cashback";
import Coupon from "../pages/user/Coupon";
import RedPacket from "../pages/user/RedPacket";
import Collection from "../pages/user/Collection";
import Orders from "../pages/user/Orders";
import Fans from "../pages/user/Fans";
import IncomeDetail from "../pages/user/IncomeDetail";
import ChangeUser from "../pages/user/ChangeUser";
import UsedCode from "../pages/user/UsedCode";
import PaySuccess from "../pages/user/PaySuccess";
import Product from "../pages/Product";
import Category from "../pages/Category";
import StoreList from "../pages/StoreList";
import Buy from "../pages/Buy";
import Food from "../pages/Food";
import Play from "../pages/Play";
import Error from "../pages/Error";

export  default [
    {
        path: '/home',
        component: Home,
    },
    {
        path: '/food',
        component: Food,
    },
    {
        path: '/play',
        component: Play,
    },
    {
        path: '/category',
        component: Category
    },
    {
        path: '/search',
        component: Search
    },
    {
        path: '/user',
        component: User
    },
    {
        path: '/cashback',
        component: Cashback
    },
    {
        path: '/fans',
        component: Fans
    },
    {
        path: '/incomeDetail',
        component: IncomeDetail
    },
    {
        path: '/coupon',
        component: Coupon
    },
    {
        path: '/redPacket',
        component: RedPacket
    },
    {
        path: '/collection',
        component: Collection
    },
    {
        path: '/orders',
        component: Orders
    },
    {
        path: '/changeUser',
        component: ChangeUser
    },
    {
        path: '/usedCode',
        component: UsedCode
    },
    {
        path: '/paySuccess',
        component: PaySuccess
    },
    {
        path: '/product',
        component: Product
    },
    {
        path: '/buy',
        component: Buy
    },
    {
        path: '/storeList',
        component: StoreList
    },
    {
        //配置默认路由
        path: "/",
        component: Home
    },
    {
        path: '/404',
        component: Error,
        hidden: true
    }
];
