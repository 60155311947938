<template>

  <div class="detail-container">
    <div class="income-header">
      <div class="income-title">
        收入金额
      </div>
      <div class="income-fee">
        <span class="fee-tag">¥</span>
        <span class="fee-num">{{item_info.fans_fee_yuan}}</span>
      </div>
    </div>
    <div class="order-container">

      <div class="order-item">
        <span class="item-title">流水号</span>
        <span class="item-content">{{item_info.item_no}}</span>
      </div>
      <div class="order-item">
        <span class="item-title">收入类型</span>
        <span class="item-content">订单分润</span>
      </div>
      <div class="order-item">
        <span class="item-title">交易状态</span>
        <span class="item-content">{{item_info.status_desc}}</span>
      </div>
      <div class="order-item">
        <span class="item-title">商品名称</span>
        <span class="item-content">{{item_info.product_title}}</span>
      </div>
      <div class="order-item">
        <span class="item-title">购买时间</span>
        <span class="item-content">{{item_info.buy_time}}</span>
      </div>
      <div class="order-item" v-if="item_info.use_status==2">
        <span class="item-title">使用时间</span>
        <span class="item-content">{{item_info.used_time}}</span>
      </div>

    </div>
  </div>

  <!-- 返回 -->
  <div class="back-index"  @click="gotoCashback">
    返回
  </div>


</template>

<script>

import {useRoute, useRouter} from 'vue-router'

import {reactive,ref} from "vue";
import api from "../../api";
import {fenToYuan} from "../../util/util";

export default {
  name: "Fans",
  setup(){

    const router = useRouter()
    const route = useRoute ()
    let itemid = ref(route.query.itemid)


    let item_info = reactive({});

    api.getSubOrderItemById({
      orderItemId: itemid.value
    }).then(rsp => {
      item_info.fans_fee_yuan = fenToYuan(rsp.fans_fee)
      item_info.item_no = rsp.item_no
      item_info.product_title = rsp.product_title
      item_info.buy_time = rsp.add_time
      item_info.use_status = rsp.use_status

      if(rsp.use_status == 0)
      {
        item_info.status_desc = '待预约'
      }
      else if(rsp.use_status == 1)
      {
        item_info.status_desc = '待使用'
      }
      else if(rsp.use_status == 2)
      {
        item_info.status_desc = '已使用'
        item_info.used_time = rsp.used_time
      }

    })

    function gotoCashback(){
      router.replace('/cashback')
    }


    return {
      item_info,
      gotoCashback
    }
  }
}
</script>

<style scoped>


.detail-container{
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.income-header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 130px;
  border-bottom: 1px solid #dcdcdc;
}

.income-title{
  font-size: 14px;
  margin-bottom: 6px;
}

.income-fee{
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.fee-tag{
  color: #10a71c;
  font-size: 30px;
  margin-right: 5px;
}

.fee-num{
  color: #10a71c;
  font-size: 40px;
  font-weight: 500;
}


.order-container{
  display: flex;
  flex-direction: column;
}

.order-item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 10px;
  font-size: 13px;
}

.item-title{
  color: #707070;
}

.item-content{
  color: #111111;
  max-width: 260px;
  /*width: 300px;*/
}

.back-index{
  margin-top: 25px;
  background-color: #ffffff;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}




</style>
