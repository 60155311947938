<template>
<!--  首页-->

  <div class="home">

    <!--头部-->
    <HomeHeader :siteno="siteno" :fansno="fansno"></HomeHeader>

    <!--排序菜单-->
    <div class="sort-menu-container">
      <span class="item" :class="{'active': current_sort_type == 0,}" @click="getProducts(0)">精品推荐</span>
      <span class="item" :class="{'active': current_sort_type == 1,}" @click="getLocation(1)">距离最近</span>
      <span class="item" :class="{'active': current_sort_type == 2,}" @click="getProducts(2)">销量最多</span>
      <span class="item" :class="{'active': current_sort_type == 3,}" @click="getProducts(3)">无需预约</span>
    </div>

      <!--产品列表-->
    <div class="product-container">

      <ProductItem v-for="productInfo of productList" :key="productInfo.id" :product="productInfo"></ProductItem>

    </div>

    <!--底部菜单-->
    <FooterMenu></FooterMenu>

  </div>

</template>

<script>

import wx from 'weixin-js-sdk'
import {delFansnoInUrl, isEmptyStr, isWeixin, getSignUrl} from "../util/util"

import ProductItem from "../components/ProductItem";
import HomeHeader from "../components/HomeHeader";
import FooterMenu from "../components/FooterMenu";
import api from "../api"
import {fenToYuan} from "../util/util";


import {useRoute } from 'vue-router';
import {onMounted, onUnmounted, reactive, ref} from "vue";

export default {
  name: "Home",
  components: {
    FooterMenu,
    HomeHeader,
    ProductItem
  },
  setup() {

    document.title = '这里，吃喝玩乐更省钱！'

    const route = useRoute ()

    //接收参数 并 缓存
    let siteno = ref(route.query.siteno);
    let fansno = ref(route.query.fansno);
    if(!isEmptyStr(siteno.value)){
      localStorage.setItem("hui_siteno", siteno.value)
    }
    if(!isEmptyStr(fansno.value)){
      localStorage.setItem("hui_fansno", fansno.value)
      delFansnoInUrl()  //删除fansno参数
    }

    let site_name = ref('')
    api.getSiteInfo().then(response => {
      site_name.value = response.title
    })


    //这里获取地理位置 begin ************************
    //第一步，加载config
    //加载微信config
    let current_url = getSignUrl()
    api.getJsapiData(current_url).then(rsp => {

      //加载微信config begin:
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: rsp.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
        timestamp: rsp.timestamp, // 必填，生成签名的时间戳
        nonceStr: rsp.nonceStr, // 必填，生成签名的随机串
        signature: rsp.signature,// 必填，签名，见附录1
        jsApiList: [
          'updateAppMessageShareData',
          'updateTimelineShareData',
          'previewImage',
          'getLocalImgData',
          'downloadImage',
          'uploadImage',
          'chooseImage',
          'openLocation',
          'getLocation',
          'hideMenuItems'
        ]
        // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      });

      // config信息验证成功会执行ready函数
      wx.ready(() => {

        console.log("This is wx.ready.")

        //进入页面后，自动调用一次获取函数
        getLocation(0)

        let share_url = 'https://a.ycs360.com/hui_app/wx/webview/initUrl?siteno=' + siteno.value + '&fno=' + localStorage.getItem("hui_fansno");

        //分享给好友
        wx.updateAppMessageShareData({
          title: site_name.value, // 分享标题
          desc: site_name.value + '，吃喝玩乐更省钱！', // 分享描述
          link: share_url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: 'https://hui365.oss-cn-shanghai.aliyuncs.com/sys/default_shop_logo1.png', // 分享图标
          success: function () {
            console.log("updateAppMessageShareData succ")
            // 设置成功
          }
        });


        // ------------"分享到朋友圈"
        wx.updateTimelineShareData({
          title: site_name.value, // 分享标题
          link: share_url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: 'https://hui365.oss-cn-shanghai.aliyuncs.com/sys/default_shop_logo1.png', // 分享图标
          success: function () {
            console.log("updateTimelineShareData succ")
            // 设置成功
          }
        });


      })

      // config信息验证失败会执行error函数
      wx.error(function (res) {
        console.log("This is wx.error.")
        console.log(res)

        getProducts(0)

      });

      //加载微信config end

    })

    //第二步，触发获取位置的具体操作
    //获取位置成功的同时，获取产品列表，参数sort_type觉得如何获取产品列表
    function getLocation(sort_type) {

      //记录是否已经查询了产品信息
      let isHaveQueryProduct = false;

      //在微信中打开，则获取位置
      if(isWeixin())
      {

        wx.getLocation({
          type: 'gcj02', //返回可以用于wx.openLocation的经纬度
          success(res) {
            //获取地理位置成功，默认已经查询了产品信息
            isHaveQueryProduct = true
            //成功是，res为： {latitude: 34.86426, longitude: 117.55601, errMsg: "getLocation:ok"}
            //授权成功：成功后保存粉丝的经纬信息（保存经纬度信息成功后，再重新调一次api获取产品信息）
            api.updateLnglat(res.longitude, res.latitude).then(rsp => {
              console.log(rsp)
              getProducts(sort_type)
            })
          },
          fail: function (err) {
            console.log("err is :")
            console.log(err)
          },
          complete: function (e) {
            //不论成功与失败，都会执行这里
            //客户同意授权时， e为：{latitude: 34.86426, longitude: 117.55601, errMsg: "getLocation:ok"}
            //客户拒绝授权时： e为：{errMsg: "getLocation:cancel"}

            // console.log("complete is :")
            // console.log(e)
            //
            //若授权失败，则强制按照“精品推荐”获取产品列表）
            if (!isHaveQueryProduct)
            {
              console.log(e)
              getProducts(0)
            }

          }
        })

      }
      else
      {
        getProducts(0)
      }

    }
    //这里获取地理位置 end ************************


    let current_sort_type = ref(0)

    const productList = reactive([])

    function getProducts(sort_type){

      //如果切换了排序方式，则从第一页开始取数据
      if(current_sort_type.value != sort_type)
      {
        offset_page.value = 0
      }

      //如果当前页是第一页(页码为0)，则清空原有数据； 否则，不能清空(为加载更多数据)
      if(offset_page.value == 0)
      {
        productList.splice(0, productList.length)
      }

      current_sort_type.value = sort_type

      //客户选择了：无需预约
      if(3 == sort_type){

        api.selectProductPageList({
          menu_type: 0,
          appointment: 1,
          offset:offset_page.value * page_size.value,
          pageSize:page_size.value
        }).then(rsp => {
          productList.push(...rsp.list)

          for(var product of productList) {
            product.sale_price_min_yuan = fenToYuan(product.sale_price_min)
            product.old_price_min_yuan = fenToYuan(product.old_price_min)
          }

          //设置分页的参数
          total_num.value = rsp.total
          no_more_data.value = total_num.value == productList.length
          is_loading.value = false

        })
      }
      //客户选择了：距离最近 或 销量最多 的排序
      else
      {

        api.selectProductPageList({
          menu_type: 0,
          sort_type: sort_type,
          offset:offset_page.value * page_size.value,
          pageSize:page_size.value
        }).then(rsp => {
          productList.push(...rsp.list)

          for(var product of productList) {
            product.sale_price_min_yuan = fenToYuan(product.sale_price_min)
            product.old_price_min_yuan = fenToYuan(product.old_price_min)
          }

          //设置分页的参数
          total_num.value = rsp.total
          no_more_data.value = total_num.value == productList.length
          is_loading.value = false

        })
      }

    }


    //统计浏览数据
    api.viewEvent({}).then(rsp => {
      console.log("viewEvent succ", "rsp", rsp)
    })

    //实现触底加载数据的功能 begin ***************************************

    let is_loading = ref(false)   //是否正在加载数据：false 没有， true 正在加载

    let no_more_data = ref(false)  //没有有更多数据：false 有更多数据，true 没有更多数据
    let total_num = ref(0)        //总数量
    let offset_page = ref(0)      //当前页码
    let page_size = ref(10)       //每页数量

    onMounted(()=>{
      console.log('---onMounted---')
      window.addEventListener('scroll', reachBottom);  //页面加载时监听滚动事件
    })

    onUnmounted(()=>{
      console.log('---onUnmounted---')
      window.removeEventListener('scroll', reachBottom) //页面离开后销毁监听事件
    })

    function reachBottom() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      //距离底部小于50就会触发
      if (scrollTop + clientHeight >= scrollHeight - 50) {

        //开始加载分页数据
        getMoreData()

      }
    }

    function getMoreData() {

      if(no_more_data.value || is_loading.value){
        return
      }

      is_loading.value = true

      //只有总量大于0时，才递增页码 （这里的判断是为了防止，刚进入该页面就调用该方法，即尚未调用getProducts获取第一页数据，就直接调用getMoreData方法）
      if(total_num.value > 0)
      {
        offset_page.value++
      }

      getProducts(current_sort_type.value)

    }
    //实现触底加载数据的功能 end ***************************************


    return {
      productList,
      getProducts,
      current_sort_type,
      siteno,
      fansno,
      getLocation
    }
  }
}
</script>

<style scoped>

.home{
  padding-bottom: 70px;
}

.product-container{
  min-height: 800px;
}


/*排序*/
.sort-menu-container{
  display: flex;
  align-items: center;
  color: #333333;
  margin-top: 10px;
  margin-bottom: 10px;
}

.item{
  width: 25%;
  height: 48px;
  line-height: 48px;
  text-align: center;
}

.sort-menu-container .active{
  font-size: 16px;
  font-weight: 700;
  background: url('../assets/images/icon_learn_selected.c2ffbed4.png') no-repeat bottom center;
  background-size: 80%;
}

</style>
