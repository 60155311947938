<template>
<!--  个人中心-->

  <!--top 头像-->
  <div class="top">
    <div class="header-pic">
      <img src="@/assets/images/user_icon_default.png">
      <span>微信用户（ID:{{fans_info.id}}）</span>
    </div>
<!--    <span v-if="is_show_set" class="auth">修改信息</span>-->
  </div>

  <!--四个菜单-->
  <div class="nav-container">
    <div class="for-height1"></div>
    <div class="for-height2"></div>
    <div class="nav-content">
      <div class="nav">
        <div class="nav-item" @click="gotoPageWithPush('/cashback')">
          <img src="@/assets/images/user_icon_income.png">
          <span>达人收入</span>
        </div>
        <div class="nav-item" @click="gotoPageWithPush('/coupon')">
          <img src="@/assets/images/user_icon_coupon.png">
          <span>优惠券</span>
        </div>
        <div class="nav-item" @click="gotoPageWithPush('/redPacket')">
          <img src="@/assets/images/user_icon_red_packet.png">
          <span>红包</span>
        </div>
        <div class="nav-item" @click="gotoPageWithPush('/collection')">
          <img src="@/assets/images/user_icon_collection.png">
          <span>产品收藏</span>
        </div>
      </div>
      <div class="use-tip"  @click="gotoTargetPage()">
        <i class="iconfont icon-guzhongtixing tip-tag"></i>
        <span class="tip-desc" v-if="unBookNum + unUseNum > 0">您有<span class="can_use_num">{{unBookNum + unUseNum}}</span>个产品可使用，去犒劳下自己吧！</span>
        <span class="tip-desc" v-else>您尚未购买任何产品，去犒劳下自己吧！</span>
        <span class="use-tip-flex1"></span>
        <span class="tip-go" v-if="unBookNum + unUseNum > 0">去使用</span>
        <span class="tip-go" v-else>去挑选</span>
      </div>
    </div>
  </div>

  <!--我的订单-->
  <div class="orders">
    <div class="orders-title">
      <span>我的订单</span>
<!--      <a @click="gotoOrders(-1)">全部订单</a>-->
    </div>
    <ul class="orders-nav">
      <li @click="gotoOrders(0)">
        <img src="@/assets/images/order_icon_unordered.png">
        <span>待预约</span>
      </li>
      <li @click="gotoOrders(1)">
        <img src="@/assets/images/order_icon_unused.png">
        <span>待使用</span>
      </li>
      <li @click="gotoOrders(2)">
        <img src="@/assets/images/order_icon_complete.png">
        <span>已完成</span>
      </li>
      <li @click="gotoOrders(3)">
        <img src="@/assets/images/order_icon_refund.png">
        <span>售后/退换</span>
      </li>
    </ul>
  </div>

  <!--我的服务-->
  <div class="service">
    <div class="service-title">
      <span>我的服务</span>
    </div>
    <ul class="orders-nav service-nav">
<!--      <li>-->
<!--        <img src="@/assets/images/service_icon_setting.png">-->
<!--        <span>个人设置</span>-->
<!--      </li>-->
      <li @click="showContact()">
        <img src="@/assets/images/service_icon_kefu.png">
        <span>联系客服</span>
      </li>
    </ul>
  </div>


  <!--底部菜单-->
  <FooterMenu></FooterMenu>


  <teleport to="body">

    <div @click="hideContact" v-if="isShowContact" class="contact-mask">
    </div>
    <div v-if="isShowContact" class="contact-container">
      <img class="contact-img" :src="contact_img">
    </div>

  </teleport>

</template>

<script>

import {useRouter} from 'vue-router'

import {reactive,ref} from "vue";
import FooterMenu from "../../components/FooterMenu";
import api from "../../api";

export default {
  name: "User",
  components: {FooterMenu},
  setup(){

    const router = useRouter()

    let is_show_set = ref(true)

    let fansno = ref(localStorage.getItem("hui_fansno"))


    //当前可用预约的产品数量
    let unBookNum = ref(0)
    //当前可用使用的产品数量
    let unUseNum = ref(0)
    api.getUnbookNumAndUnuseNum().then(rsp => {
      unBookNum.value = rsp.unBookNum
      unUseNum.value = rsp.unUseNum
    })

    function gotoPageWithPush(path){
      router.push(path)
    }

    function gotoOrders(status){
      console.log(status)
      router.push("/orders?status=" + status)
    }

    function gotoTargetPage(){
      if(unUseNum.value > 0)
      {
        router.push("/orders?status=1")
      }
      else if(unBookNum.value > 0)
      {
        router.push("/orders?status=0")
      }
      else
      {
        router.push("/home")
      }
    }

    let isShowContact = ref(false)
    function showContact(){
      isShowContact.value = true
    }

    function hideContact(){
      isShowContact.value = false
    }

    let contact_img = ref('')
    api.getSiteInfo().then(response => {
      contact_img.value = response.kefu_pic
    })

    let fans_info = reactive({});
    api.getFansInfo().then(response => {
      fans_info.id = response.id
    })

    return {
      fansno,
      is_show_set,
      gotoPageWithPush,
      gotoOrders,
      isShowContact,
      showContact,
      hideContact,
      contact_img,
      unBookNum,
      unUseNum,
      gotoTargetPage,
      fans_info
    }
  }
}
</script>

<style scoped>
/********* 头部 *********/
.top{
  display: flex;
  width: 100%;
  height: 96px;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(248, 163, 6);
}

.header-pic{
  display: flex;
  align-items: center;
  margin-top: -10px;
  margin-left: 15px;
}

.header-pic img{
  width: 46px;
  height: 46px;
  border: 2px solid #ffffff;
  border-radius: 50%;
}

.header-pic span{
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  margin-left: 5px;
}

.auth{
  position: relative;
  color: #ffffff;
  font-size: 14px;
  margin-right: 25px;
  margin-top: -10px;
}

.auth::after{
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  border-top: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  top: 6px;
  margin-left: 2px;
  transform: rotate(45deg);
}

/********** 四个菜单 **********/
.nav-container{
  position: relative;
  width: 100%;
  /*height: 132px;*/
}

.for-height1{
  height: 80px;
}

.for-height2{
  height: 52px;
}

.nav-content{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 15px;
  width: calc(100% - 30px);
  background-color: #ffffff;
  border-radius: 10px;
  top: -15px;
  overflow: hidden;
  z-index: 2;
}

.nav{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 90px;
}

.nav-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #2a2a2a;
  font-size: 12px;
}

.nav-item img{
  width: 32px;
  height: 32px;
  margin-bottom: 5px;
}

.use-tip{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 15px;
  height: 50px;
  border-top: 1px solid #f5f5f5;
}

.tip-tag{
  color: #e76b69;
  margin-right: 6px;
}

.tip-desc{
  color: #6f6f6f;
  font-size: 13px;
}

.can_use_num{
  font-size: 14px;
  color: #333333;
  margin: 0 2px;
}

.use-tip-flex1{
  flex: 1;
}

.tip-go{
  position: relative;
  color: #e76b69;
  font-size: 14px;
  margin-right: 15px;
}

.tip-go::after{
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  border-top: 2px solid #e76b69;
  border-right: 2px solid #e76b69;
  top: 5px;
  margin-left: 2px;
  transform: rotate(45deg);
}






.auth::after{
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  border-top: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  top: 6px;
  margin-left: 2px;
  transform: rotate(45deg);
}

/********** 我的订单 **********/
.orders, .service{
  margin: 2px 15px;
  height: 140px;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.service{
  margin-top: 8px;
}

.orders-title, .service-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 15px;
  border-bottom: 1px solid #f5f5f5;
}

.orders-title span, .service-title span{
  color: #2a2a2a;
  font-size: 16px;
  height: 46px;
  line-height: 46px;
}


.orders-title a{
  position: relative;
  color: #969696;
  font-size: 14px;
  margin-right: 15px;
}

.orders-title a::after{
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  border-top: 2px solid #969696;
  border-right: 2px solid #969696;
  top: 6px;
  margin-left: 2px;
  transform: rotate(45deg);
}

.orders-nav{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
}

.orders-nav li{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
}

.orders-nav li img{
  width: 28px;
  margin-bottom: 5px;
}


/*客服二维码*/
.contact-mask{
  position: fixed;
  top: 0;bottom: 0;left: 0;right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
}

.contact-container{
  position: fixed;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  width: 70%;
  max-height: 400px;
  z-index: 11;
  border-radius: 10px;
  overflow: hidden;
}

.contact-img{
  min-width: 100%;
  min-height: 100%;
}


</style>
