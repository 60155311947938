<template>

  <router-view></router-view>

</template>

<script>

import {onMounted} from "vue";

export default {
  name: 'App',
  setup() {

    onMounted(()=>{
      localStorage.setItem("hui_WxCodeUrl", window.location.href)
    })

    return {
    }

  }
}
</script>

<style>
/*公共样式：begin*/
body {
  position: relative;
  max-width: 540px;
  min-width: 320px;
  margin: 0 auto;
  font: normal 14px/1.5 Tahoma, "Lucida Grande", Verdana, "Microsoft Yahei", STXihei, hei;
  color: #000;
  background: #f6f6f6;
  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

div {
  box-sizing: border-box;
}

img{
  display: flex;
}

.num-font{
  font-family: num-font!important;
}

input:focus { outline: none; }


/*修改输入框的placeholder颜色*/
::-webkit-input-placeholder{color: #cccccc}    /* 使用webkit内核的浏览器 */
:-moz-placeholder{color: #cccccc}                  /* Firefox版本4-18 */
::-moz-placeholder{color: #cccccc}                  /* Firefox版本19+ */
:-ms-input-placeholder{color: #cccccc}           /* IE浏览器 */


/*公共样式：end*/









</style>
