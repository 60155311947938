<template>

  <div class="store-info">
    <div class="img-container" :style="{backgroundImage: 'url(' + store_info.logo + ')'}" @click="showMap(store_info.lng, store_info.lat, store_info.name, store_info.address)">
<!--      <img src="@/assets/images/shop.png" alt="门店" >-->
    </div>
    <div class="address-container">
      <span @click="showMap(store_info.lng, store_info.lat, store_info.name, store_info.address)">{{store_info.name}}</span>
      <div class="shop-address" @click="showMap(store_info.lng, store_info.lat, store_info.name, store_info.address)">
        <span class="shop-address-info">{{store_info.address}}</span>
        <i v-if="store_info.distance != null" class="iconfont icon-map shop-distance"></i>
        <span v-if="store_info.distance != null" class="shop-distance">距离我{{store_info.distance}}</span>
      </div>
      <a :href="'tel:' + store_info.service_mobile"><i class="iconfont icon-phone"></i></a>
    </div>
  </div>

</template>

<script>

import wx from 'weixin-js-sdk'
import api from "../api"
import {getSignUrl} from "../util/util";

export default {
  name: "StoreItem",
  props:{
    store_info: Object
  },
  setup(props){
    console.log("this is store item.")
    console.log(props.store_info)

    let current_url = getSignUrl()
    api.getJsapiData(current_url).then(rsp => {

      //加载微信config begin:
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: rsp.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
        timestamp: rsp.timestamp, // 必填，生成签名的时间戳
        nonceStr: rsp.nonceStr, // 必填，生成签名的随机串
        signature: rsp.signature,// 必填，签名，见附录1
        jsApiList: [
          'updateAppMessageShareData',
          'updateTimelineShareData',
          'previewImage',
          'getLocalImgData',
          'downloadImage',
          'uploadImage',
          'chooseImage',
          'openLocation',
          'getLocation',
          'hideMenuItems'
        ]
        // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      });

      // config信息验证成功会执行ready函数
      wx.ready(() => {
        console.log("This is wx.ready.")
      })

      // config信息验证失败会执行error函数
      wx.error(function (res) {
        console.log("This is wx.error.")
        console.log(res)
      });

      //加载微信config end
    })






    function showMap(lng, lat, name, address){

      wx.openLocation({
        latitude: Number(lat), // 纬度，浮点数，范围为90 ~ -90
        longitude: Number(lng), // 经度，浮点数，范围为180 ~ -180。
        name: name, // 位置名
        address: address, // 地址详情说明
        scale: 14, // 地图缩放级别,整形值,范围从1~28。默认为最大
        infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
      });

    }

    return {
      showMap
    }
  }
}
</script>

<style scoped>

.store-info{
  display: flex;
  flex-direction: row;
}

.img-container{
  width: 70px;
  height: 70px;
  flex-shrink: 0;
  background: bottom center no-repeat;
  background-size: cover;
  border-radius: 6px;
}

/*.img-container img{*/
/*  !*width: 70px;*!*/
/*  !*height: 70px;*!*/
/*  border-radius: 6px;*/
/*  max-width:100%;*/
/*  max-height:100%;*/
/*  vertical-align: middle;*/
/*  margin: 0 auto;*/
/*}*/

.address-container{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  margin-left: 6px;
  height: 70px;
  width: 100%;
}

.address-container>span{
  font-size: 14px;
  color: #2a2a2a;
  font-weight: 600;
}

.icon-phone{
  position: absolute;
  top:-4px;
  right: 10px;
  font-size: 20px;
  color: #000000;
  /*font-weight: 600;*/
  /*color: #fa3c1f;*/
}

.shop-address-info{
  color: #333333;
  font-size: 12px;
}

.shop-address i{
  margin-left: 20px;
}

.shop-distance{
  color: #999999;
  font-size: 10px;
}

</style>
