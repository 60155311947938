<template>

  <div class="header">
    <span class="title">累计提现 / 冻结中</span>
    <div class="money">
      <span class="money-tag num-font">¥</span>
      <span class="money-amount">{{current_fans.profit_fee_succ_yuan}}</span>
      <span class="money-amount">/</span>
      <span class="money-amount">{{current_fans.profit_fee_lock_yuan}}</span>
    </div>
  </div>

  <div class="type">
    <div class="item-title" :class="{active:item_type==0}" @click="chgItemType(0)">
      <span class="desc">佣金记录</span>
      <span class="selected-line"></span>
    </div>
    <div class="item-title" :class="{active:item_type==1}" @click="chgItemType(1)">
      <span class="desc">粉丝记录</span>
      <span class="selected-line"></span>
    </div>
<!--    <div class="item-title" :class="{active:item_type==2}" @click="chgItemType(2)">-->
<!--      <span class="desc">订单记录</span>-->
<!--      <span class="selected-line"></span>-->
<!--    </div>-->
  </div>

  <div v-if="item_type==0" class="data-container">
    <div v-if="fee_list.length > 0 && loading_fee_flag" class="data-list">

      <div class="fee-item-container">

        <div class="fee-item" v-for="fee_info of fee_list" :key="fee_info.id" @click="gotoIncomeDetail(fee_info.id)">
          <span class="fee-time">{{fee_info.fee_time}} <span class="fee-status">{{fee_info.use_status == 2 ?"":"(未核销，冻结中)"}}</span></span>
          <span style="flex: 1"></span>
          <span class="fans-fee-yuan">+{{fee_info.fee_yuan}}</span>
          <i class="iconfont icon-arrow-right"></i>
        </div>

      </div>

    </div>
    <div v-if="fee_list.length == 0 && loading_fee_flag" class="no-data">
      <img src="https://hui365.oss-cn-shanghai.aliyuncs.com/sys/icon_no_cashback.png">
      <span class="no-data-desc">暂无佣金记录</span>
    </div>
  </div>

  <div v-else-if="item_type==1" class="data-container">
    <div v-if="fans_list.length > 0 && loading_fans_flag" class="data-list">

      <div class="fans-item" v-for="fans_info of fans_list" :key="fans_info.id"  @click="gotoFans(fans_info.fansno)">
        <img class="fans-header-pic" src="https://hui365.oss-cn-shanghai.aliyuncs.com/sys/default_header_orange.svg">
        <div class="fans-info-container">
          <div class="fans-id-info">
            <span class="fans-name">微信用户 <span class="fans-id">(ID:{{fans_info.id}})</span></span>
            <i class="iconfont icon-arrow-right"></i>
          </div>
          <div class="fans-buy">订单：{{fans_info.total_order_num}} / 成交：¥{{fans_info.total_pay_fee_yuan}}</div>
          <div class="fans-profit">
            <span class="profit-money">佣金贡献：¥{{fans_info.total_parent_fee_yuan}}</span>
            <span class="last-view-time">{{fans_info.last_day}}来过</span>
          </div>
        </div>
      </div>

    </div>
    <div v-if="fans_list.length == 0 && loading_fans_flag" class="no-data">
      <img src="https://sfxd2021.oss-cn-shanghai.aliyuncs.com/sys/icon_no_fans_record.png">
      <span class="no-data-desc">暂无粉丝记录</span>
    </div>
  </div>

  <div v-else-if="item_type==2" class="data-container">
    <div class="data-list">

    </div>
    <div class="no-data">
      <img src="https://sfxd2021.oss-cn-shanghai.aliyuncs.com/sys/icon_no_order_record.png">
      <span class="no-data-desc">暂无订单记录</span>
    </div>
  </div>

</template>

<script>
import {reactive, ref} from "vue";
import api from "../../api";
import {fenToYuan} from "../../util/util";
import {useRouter} from "vue-router";

export default {
  name: "Cashback",
  setup(){

    const router = useRouter()

    let item_type = ref(0)  //0 佣金记录； 1 粉丝记录

    function chgItemType(select_type){

      if(item_type.value != select_type){
        item_type.value = select_type

        if(0 == select_type){
          loading_fee_flag.value = false
          getFeeList()
        }
        else if(1 == select_type){
          loading_fans_flag.value = false
          selectFansList()
        }

      }

    }

    const current_fans = reactive({})
    api.getFansInfo().then(rsp => {

      current_fans.profit_fee_succ_yuan = fenToYuan(rsp.profit_fee_succ)
      current_fans.profit_fee_lock_yuan = fenToYuan(rsp.profit_fee_lock)

    })


    const fee_list = reactive([])      //佣金记录
    let fee_is_loading = ref(false)   //是否正在加载数据：false 没有， true 正在加载
    let fee_no_more_data = ref(false)  //没有有更多数据：false 有更多数据，true 没有更多数据
    let fee_total_num = ref(0)        //总数量
    let fee_offset_page = ref(0)      //当前页码
    let fee_page_size = ref(10)       //每页数量
    let loading_fee_flag = ref(false)

    const fans_list = reactive([])     //粉丝记录
    let fans_is_loading = ref(false)   //是否正在加载数据：false 没有， true 正在加载
    let fans_no_more_data = ref(false)  //没有有更多数据：false 有更多数据，true 没有更多数据
    let fans_total_num = ref(0)        //总数量
    let fans_offset_page = ref(0)      //当前页码
    let fans_page_size = ref(10)       //每页数量
    let loading_fans_flag = ref(false)


    function getFeeList(){

      //如果当前页是第一页(页码为0)，则清空原有数据； 否则，不能清空(为加载更多数据)
      if(fee_offset_page.value == 0)
      {
        fee_list.splice(0, fee_list.length)
      }

      api.selectFeeList({
        offset:fee_offset_page.value * fee_page_size.value,
        pageSize:fee_page_size.value
      }).then(rsp => {
        fee_list.push(...rsp.list)

        for(var fee_info of fee_list) {
          fee_info.fee_yuan = fenToYuan(fee_info.fans_fee)
          fee_info.fee_time = fee_info.add_time.substr(0, 16)
        }

        //设置分页的参数
        fee_total_num.value = rsp.total
        fee_no_more_data.value = fee_total_num.value == fee_list.length
        fee_is_loading.value = false

        loading_fee_flag.value = true
      })


    }
    getFeeList()

    function selectFansList(){

      //如果当前页是第一页(页码为0)，则清空原有数据； 否则，不能清空(为加载更多数据)
      if(fans_offset_page.value == 0)
      {
        fans_list.splice(0, fans_list.length)
      }

      api.selectFansList({
        offset:fans_offset_page.value * fans_page_size.value,
        pageSize:fans_page_size.value
      }).then(rsp => {
        fans_list.push(...rsp.list)

        for(var fans_info of fans_list) {
          fans_info.total_pay_fee_yuan = fenToYuan(fans_info.total_pay_fee)
          fans_info.total_parent_fee_yuan = fenToYuan(fans_info.total_parent_fee)
          fans_info.last_day = fans_info.last_view_time.substr(0,10)
        }

        //设置分页的参数
        fans_total_num.value = rsp.total
        fans_no_more_data.value = fans_total_num.value == fans_list.length
        fans_is_loading.value = false

        loading_fans_flag.value = true

      })


    }


    function gotoFans(fansno){
      router.push("/fans?fansno=" + fansno)
    }

    function gotoIncomeDetail(orderItemId){
      router.push("/incomeDetail?itemid=" + orderItemId)
    }


    return {
      current_fans,
      item_type,
      chgItemType,
      fee_list,
      fans_list,
      loading_fee_flag,
      loading_fans_flag,
      gotoFans,
      gotoIncomeDetail
    }

  }
}
</script>

<style scoped>

/*********** header ***********/
.header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  border-radius: 8px;
  height: 130px;
  color: #ffffff;
  background: linear-gradient(to right, #ffaf23, #ff9b23);
  box-shadow:0px 0px 2px 1px rgba(255,156,35,0.3);
}

.title{
  font-size: 14px;
}

.money{
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.money-tag{
  font-size: 24px;
  font-weight: 500;
  margin-right: 8px;
}

.money-amount{
  font-size: 36px;
  font-weight: 600;
  margin-right: 6px;
}

/*********** type ***********/
.type{
  display: flex;
  justify-content: space-around;
  margin: 10px;
  color: #2f2f2f;
  font-size: 16px;
  font-weight: 500;
}

.item-title{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 30%;
}


.desc{
  margin-bottom: 6px;
}

.active .desc{
  color: #ff9b23;
}

.selected-line{
  display: inline-block;
  width: 30px;
  height: 1px;
}

.active .selected-line{
  background-color: #ff9b23;
}

/*********** data-container ***********/
.data-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
}

.no-data{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 150px;
}

.no-data img{
  width: 50%;
}

.no-data-desc{
  color: #2f2f2f;
  font-size: 16px;
  font-weight: 500;
}



/*********** 佣金记录 ***********/
.fee-item-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  background-color: #ffffff;
  border-radius: 10px;
}

.fee-item{
  height: 60px;
  line-height: 60px;
  font-size: 14px;
  border-bottom: dashed 2px #ececec;
  box-sizing: border-box;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fee-item:last-child{
  border-bottom: none;
}

.fee-status{
  font-size: 12px;
  color: #999999;
  margin-left: 4px;
}

/*********** 粉丝记录 ***********/

.fans-item{
  display: flex;
  align-items: flex-start;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 15px 20px 10px 20px;
  border-radius: 12px;
  margin-top: 10px;
}

.fans-header-pic{
  width: 60px;
  height: 60px;
}

.fans-info-container{
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 100%;
}

.fans-id-info{
  display: flex;
  justify-content: space-between;
}

.fans-name{
  font-size: 16px;
  color: #333333;
  font-weight: 600;
  height: 30px;
  line-height: 30px;
}

.fans-id{
  font-size: 16px;
  color: #666666;
  height: 30px;
  line-height: 30px;
  font-weight: 500;
}

.fans-buy{
  height: 30px;
  line-height: 30px;
  color: #666666;
}

.fans-profit{
  display: flex;
  justify-content: space-between;
  height: 30px;
  line-height: 30px;
}

.profit-money{
  color: #ee463b;
}

.last-view-time{
  color: #666666;
}


</style>
