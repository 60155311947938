<template>
<!--  个人中心-->

  <!--top 头像-->
  <div class="top">
    <img class="header-pic" src="@/assets/images/user_icon_default.png">
    <span class="fans-name">微信用户（ID:{{fans_info.id}}）</span>
    <span class="login-time">加入时间：{{fans_info.add_time}}</span>
  </div>
  <div class="profit-money">
    <span class="profit-money-ta">TA</span>
    <span class="profit-money-desc">为您带来佣金收入共计：</span>
    <span class="profit-money-num num-font">¥{{fans_info.total_parent_fee_yuan}}</span>
  </div>

  <!--订单列表-->
  <ul class="data-container" v-if="orderItemList.length > 0">
    <li class="data-item" v-for="orderItem of orderItemList" :key="orderItem.id">
      <div class="flow-no-container">
        <div class="flow-no">
          <i class="iconfont icon-liushuihao"></i>
          <span>订单号: {{orderItem.item_no}}</span>
        </div>
        <span class="order-status unbook" v-if="orderItem.use_status == 0">待预约</span>
        <span class="order-status unuse" v-if="orderItem.use_status == 1">待使用</span>
        <span class="order-status used" v-if="orderItem.use_status == 2">已使用</span>
        <span class="order-status cancel" v-if="orderItem.use_status == 3">
          <span v-if="orderItem.refund_status == 1 || orderItem.refund_status == 2 ">退款中</span>
          <span v-else-if="orderItem.refund_status == 3">退款成功</span>
          <span v-else-if="orderItem.refund_status == 4">退款失败</span>
        </span>
      </div>

      <div class="product-info">
        <div class="product-pic" :style="{backgroundImage: 'url(' + orderItem.cover_pic + ')'}"></div>
        <div class="info">
          <span class="title">{{orderItem.title}}</span>
          <span class="desc">{{orderItem.description}}</span>
        </div>
      </div>


      <div class="user-info">
        <div class="user-title-container">
          <div class="user-title">
            <i class="iconfont icon-user"></i>
            <span>预留信息（使用者）</span>
          </div>
        </div>
        <div class="user-detail">
          <div class="detail-item">
            <i class="iconfont icon-point-01"></i>
            <span class="detail-title">姓名：</span>
            <span class="detail-content">{{orderItem.user_name}}</span>
          </div>
          <div class="detail-item">
            <i class="iconfont icon-point-01"></i>
            <span class="detail-title">手机：</span>
            <span class="detail-content">{{orderItem.user_phone}}</span>
          </div>
        </div>
      </div>

      <div class="shop-info">
        <div class="shop-title-container">
          <div class="shop-title">
            <span>本单佣金</span>
          </div>
          <span class="money-amount">¥{{orderItem.fans_fee_yuan}}</span>
        </div>

      </div>

      <div class="price-container">
        <span class="time-info">时间：{{orderItem.add_time}}</span>
        <div class="price-info">
          <span class="pay-desc">订单价格：</span>
          <span class="money-tag num-font">¥</span>
          <span class="money-amount">{{orderItem.item_fee_yuan}}</span>
        </div>
      </div>
      <div v-if="orderItem.buy_num > 1" class="split-info">说明：实付款为拆分后的金额（即每份的价格）</div>

    </li>

  </ul>
  <div v-else class="no-data">
    <img src="https://sfxd2021.oss-cn-shanghai.aliyuncs.com/sys/icon_no_order_record.png">
    <span class="no-data-desc1">暂时没有订单</span>
  </div>


</template>

<script>

import {useRoute} from 'vue-router'

import {reactive,ref} from "vue";
import api from "../../api";
import {fenToYuan} from "../../util/util";

export default {
  name: "Fans",
  setup(){

    const route = useRoute ()
    let sub_fansno = ref(route.query.fansno)


    let fans_info = reactive({});
    api.getFansInfoByFansno({
      fansno: sub_fansno.value
    }).then(rsp => {
      fans_info.id = rsp.id
      fans_info.last_view_time = rsp.last_view_time
      fans_info.total_parent_fee_yuan = fenToYuan(rsp.total_parent_fee)
    })


    const orderItemList = reactive([])
    function getOrderItemListByFansno(){

      //首先清空数组中原来的数据
      orderItemList.splice(0, orderItemList.length)

      api.getOrderItemListByFansno({
        fansno:sub_fansno.value
      }).then(rsp => {
        orderItemList.push(...rsp)

        for(var orderItem of orderItemList) {
          orderItem.item_fee_yuan = fenToYuan(orderItem.item_fee)

          if(-1 == orderItem.fans_fee){
            orderItem.fans_fee_yuan = 0
          }
          else {
            orderItem.fans_fee_yuan = fenToYuan(orderItem.fans_fee)
          }

        }

      })
    }

    getOrderItemListByFansno(status.value)

    return {
      fans_info,
      orderItemList
    }
  }
}
</script>

<style scoped>
/********* 头部 *********/
.top{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 180px;
  justify-content: start;
  align-items: center;
  background-color: rgb(248, 163, 6);
}

.header-pic{
  width: 60px;
  height: 60px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  margin-top: 30px;
}

.fans-name{
  font-size: 18px;
  color: #ffffff;
  line-height: 30px;
  height: 30px;
  margin-top: 6px;
}

.login-time{
  font-size: 16px;
  color: #ffffff;
  line-height: 30px;
  height: 30px;
}

.profit-money{
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  background-color: #ffffff;
  font-size: 16px;
}

.profit-money-ta{
  color: #4c92da;
  margin-left: 10px;
  margin-right: 4px;
}

.profit-money-num{
  color: #ee463b;
  font-size: 16px;
}




/**** 订单数据 ****/
.data-container{
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 10px;
  margin-bottom: 20px;
}

.data-item{
  display: flex;
  flex-direction: column;
  justify-content: start;
  background-color: #ffffff;
  margin: 10px 10px 4px 10px;
  border-radius: 10px;
  padding: 0 10px 10px 10px;
}

/*流水号信息*/
.flow-no-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e2e2e2;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
}

.flow-no{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-left: 4px;
}

.flow-no i{
  margin-right: 4px;
}

.order-status{
  margin-right: 4px;
}

.order-status.unbook, .order-status.unuse{
  color: #67c23a;
  font-weight: 600;
}

.order-status.used, .order-status.cancel{
  color: #666666;
  font-weight: 600;
}

/*产品信息*/
.product-info{
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px dashed #e2e2e2;
}

.product-pic{
  width: 75px;
  height: 75px;
  border-radius: 8px;
  flex-shrink: 0;
  margin-right: 10px;
  background: bottom center no-repeat;
  background-size: cover;
}

.info{
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.title{
  font-size: 14px;
  font-weight: 600;
  color: #1f1f1f;
  margin-bottom: 10px;

  white-space:nowrap;
  text-overflow:ellipsis;
  overflow:hidden;
  word-break:break-all;
}

.desc{
  font-size: 12px;
  line-height: 20px;
  color: #656565;

  /*超出2行隐藏 + 省略号*/
  height: auto;
  display:-webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2;
}

/*预留信息（使用者）*/
.user-info{
  padding-bottom: 16px;
  border-bottom: 1px dashed #e2e2e2;
}

.user-title-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  font-size: 14px;
}

.user-title{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-left: 4px;
}

.user-title i{
  margin-right: 4px;
}

.user-detail{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 6px 0 0 20px;
}

.detail-item{
  display: flex;
  flex-direction: row;
}

.detail-title{
  margin-right: 6px;
  font-size: 13px;
  font-weight: 500;
  color: #2a2a2a;
}

.detail-content{
  font-size: 13px;
  color: #2a2a2a;
}

/*适用店铺*/
.shop-info{
  padding-bottom: 16px;
  border-bottom: 1px dashed #e2e2e2;
}

.shop-title-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  font-size: 14px;
}

.shop-title{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-left: 4px;
}

.shop-title i{
  margin-right: 4px;
}


.more-shop{
  position: relative;
  color: #969696;
  font-size: 13px;
  margin-right: 15px;
}

.more-shop::after{
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  border-top: 2px solid #969696;
  border-right: 2px solid #969696;
  top: 6px;
  margin-left: 2px;
  transform: rotate(45deg);
}

.shop-item{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  margin-top: 10px;
}

.shop-item .icon-righta{
  margin-left: 10px;
  margin-right: 10px;
}

.shop-item i{
  font-size: 14px;
  color: #2a2a2a;
}

.shop-detail{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-self: start;
}

.shop-name{
  font-size: 14px;
  font-weight: 600;
  color: #2a2a2a;
}

.shop-address{
  font-size: 12px;
}

.shop-item .icon-phone{
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

/*价格*/
.price-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 4px 0 4px;
  padding-bottom: 10px;
}

.time-info{
  color: #9a9a9a;
  font-size: 12px;
}

.price-info{
  color: #1f1f1f;
  font-size: 16px;
  font-weight: 500;
}
.money-tag{
  margin-right: 2px;
}
.money-tag, .money-amount{
  color: #c24f4a;
}

.split-info{
  color: #9a9a9a;
  font-size: 12px;
  width: 100%;
  margin: 0 0 6px 0;
}

/*操作按钮*/
.oper-container{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  font-size: 12px;
  color: #112f63;
}

div.btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 30px;
  border: 1px solid #112f63;
  border-radius: 15px;
  margin-right: 20px;
}

div.btn:last-child{
  margin-right: 6px;
}

div.btn i{
  margin-right: 6px;
}



.no-data{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 150px;
}

.no-data img{
  width: 50%;
}

.no-data-desc1{
  color: #2f2f2f;
  font-size: 16px;
  font-weight: 500;
  margin-top: 4px;
}

.no-data-desc2{
  color: #999999;
}

</style>
